import React from "react";
import { Chip, Avatar, Box } from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";
import { Flipper, Flipped } from "react-flip-toolkit";
import UserProfileCard from "./card";
import PaperTip from "../paper-tip";
import { makeStyles } from "@material-ui/core/styles";

const UserProfileChip = ({
	org,
	isFetched,
	id,
	name,
	nickname,
	picture,
	role,
	isOwner,
	email,
	baseElevation,
	chipProps,
	returnUrl,
	returnLabel,
	returnState
}) => {
	const tooltipContents = (
		<div>
			<UserProfileCard
				{...{
					org,
					isFetched,
					id,
					name,
					picture,
					role,
					email,
					isOwner,
					baseElevation,
					returnUrl,
					returnLabel,
					returnState
				}}
			/>
		</div>
	);

	const classes = useStyles();
	return (
		<>
			<Flipper flipKey={isFetched} element="div" className={classes.flipper}>
				<Flipped flipId="user-chip-root">
					<div>
						<PaperTip title={tooltipContents}>
							<div>
								<Chip
									variant="outlined"
									size="small"
									avatar={<Avatar src={isFetched ? picture : null} />}
									label={
										<>
											<Flipped inverseFlipId="user-chip-label">
												{isFetched ? (
													<Box>
														{nickname}{" "}
														{isOwner ? (
															<VerifiedUser
																style={{ fontSize: "1em", opacity: 0.75 }}
															/>
														) : null}
													</Box>
												) : (
													<Box style={{ opacity: 0.2 }}>User</Box>
												)}
											</Flipped>
										</>
									}
									{...chipProps}
								/>
							</div>
						</PaperTip>
					</div>
				</Flipped>
			</Flipper>
		</>
	);
};

const useStyles = makeStyles(() => {
	return {
		flipper: { display: "inline-block" }
	};
});

export default UserProfileChip;
