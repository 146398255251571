import React, { useCallback } from "react";
import {
	Box,
	MenuItem,
	ListItemIcon,
	ListItemText,
	LinearProgress,
	ListItemSecondaryAction,
	Button
} from "@material-ui/core";
import PackageIcon from "../../../assets/package-icon";
import {
	ErrorOutline as ErrorOutlineIcon,
	Refresh as RefreshIcon,
	Close as CloseIcon
} from "@material-ui/icons";
import useHandleReadyState from "./use-handle-ready-state";
import useHandleProgress from "./use-handle-progress";
import useQueuePackage from "./use-queue-package";
import { uploadStatus, uploadAction } from "../../reducer";
import { makeStyles } from "@material-ui/core/styles";
import { Flipped } from "react-flip-toolkit";
import PrimaryText from "./primary-text";
import SecondaryText from "./secondary-text";
import ErrorMessage from "../../../error-message";
import MoreMenu from "../../../more-menu";

const Pkg = ({
	id,
	type,
	version,
	versions,
	status,
	message,
	helpLink,
	progress,
	file,
	org,
	uploadDispatch
}) => {
	const fileName = file?.name;
	const handlerOpts = { fileName, uploadDispatch };
	const handleProgress = useHandleProgress(handlerOpts);
	const handleReadyState = useHandleReadyState(handlerOpts);

	useQueuePackage({
		status,
		file,
		uploadDispatch,
		handleProgress,
		handleReadyState,
		org
	});

	const isTransferring = status === uploadStatus.TRANSFERRING;
	const isError = status === uploadStatus.ERROR;

	const handleRequeue = useCallback(() => {
		uploadDispatch({
			type: uploadAction.REQUEUE,
			payload: { fileName }
		});
	}, [fileName, uploadDispatch]);

	const handleAbort = useCallback(() => {
		uploadDispatch({ type: uploadAction.UPLOAD_CANCEL, payload: { fileName } });
	}, [fileName, uploadDispatch]);

	const classes = useStyles({ progress });

	return (
		<>
			<Flipped flipId={`upload${id || file.name}`}>
				<div className={classes.root}>
					{isTransferring ? (
						<LinearProgress
							classes={{ root: classes.progressRoot }}
							variant={progress === 0 ? "query" : "determinate"}
							value={progress}
						/>
					) : null}
					<div className={classes.content}>
						<div className={classes.iconPrimary}>
							<Box pr={2}>
								{isError ? (
									<ErrorOutlineIcon />
								) : (
									<PackageIcon type={type} fontSize="large" />
								)}
							</Box>
							<Flipped flipId={`pkg-primary-${id}`}>
								<div className={classes.primaryText}>
									<PrimaryText
										{...{ isTransferring, isError, fileName, id, org }}
									/>
								</div>
							</Flipped>
						</div>
						<SecondaryText
							{...{
								id,
								org,
								isTransferring,
								progress,
								isError,
								message,
								helpLink,
								version,
								versions
							}}
						/>
						{isError ? (
							<ListItemSecondaryAction>
								<MoreMenu>
									<MenuItem onClick={handleRequeue}>
										<ListItemIcon>
											<RefreshIcon />
										</ListItemIcon>
										<ListItemText primary="Retry" />
									</MenuItem>
									<MenuItem onClick={handleAbort}>
										<ListItemIcon>
											<CloseIcon />
										</ListItemIcon>
										<ListItemText primary="Abort" />
									</MenuItem>
								</MoreMenu>
							</ListItemSecondaryAction>
						) : null}
					</div>
				</div>
			</Flipped>
			<ErrorMessage
				req={{
					error: isError ? { message } : false,
					headers: { Date: new Date().getTime() },
					body: { message, helpLink }
				}}
				verb="uploading"
				description={`package ${fileName}`}
				action={[
					<Button key="retry" onClick={handleRequeue} color="inherit">
						Retry
					</Button>,
					<Button key="abort" onClick={handleAbort} color="inherit">
						Abort
					</Button>
				]}
			></ErrorMessage>
		</>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			position: "relative"
		},
		content: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			padding: theme.spacing(1, 2),
			[theme.breakpoints.up("md")]: {
				flexDirection: "row"
			},
			zIndex: 1
		},
		iconPrimary: {
			display: "flex",
			[theme.breakpoints.up("md")]: {
				flexBasis: "33%"
			},
			flexFlow: "wrap"
		},
		progressRoot: {
			zIndex: 0,
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			height: "100%",
			opacity: ({ progress }) => (progress === 0 ? 0.25 : 0.375)
		}
	};
});

export default Pkg;
