import React from "react";
import PkgVersionInline from "./inline";
import PkgVersionCard from "./card";
import { startCase } from "lodash";

const VARIANTS = {
	Inline: PkgVersionInline,
	Card: PkgVersionCard
};

const PkgVersion = ({ variant = "Inline", ...props }) => {
	if (!VARIANTS[variant])
		throw new TypeError(
			`variant \`${variant}\` not found. did you mean \`${startCase(
				variant
			)}\`?`
		);
	const VariantComponent = VARIANTS[variant];
	return <VariantComponent {...props} />;
};

export default PkgVersion;
