import React from "react";
import { useSpring, animated } from "react-spring";

const ProgressText = ({ progress }) => {
	const progressValue = useSpring({
		to: { number: progress || 0 },
		from: { number: 0 }
	});
	const interpolated = progressValue.number.interpolate(
		v => `${v.toFixed(2).padStart(5, "0")}%`
	);

	return <animated.span>{interpolated}</animated.span>;
};

export default ProgressText;
