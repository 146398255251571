import React from "react";
import { Box } from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";
import UserProfileCard from "./card";

import getUserRoute from "./get-user-route";
import PaperTip from "../paper-tip";
import Link from "../refable-link";

const UserProfileLink = props => {
	const {
		org,
		isFetched,
		userId,
		baseElevation,
		returnUrl,
		returnLabel,
		returnState,
		nickname,
		isOwner,
		isSystem
	} = props;

	const tooltipContents = isSystem ? null : (
		<div>
			<UserProfileCard {...props} />
		</div>
	);

	return isSystem ? (
		<>System</>
	) : (
		<PaperTip
			title={tooltipContents}
			elevation={Math.min(24, baseElevation + 2)}
		>
			<Link
				to={getUserRoute({ org, userId })}
				color="textPrimary"
				state={{ returnUrl, returnLabel, returnState }}
			>
				<>
					{isFetched || nickname ? (
						<Box display="inline-block">
							{nickname}{" "}
							{isOwner ? (
								<VerifiedUser style={{ fontSize: "1em", opacity: 0.75 }} />
							) : null}
						</Box>
					) : (
						<Box display="inline-block" style={{ opacity: 0.2 }}>
							User
						</Box>
					)}
				</>
			</Link>
		</PaperTip>
	);
};

export default UserProfileLink;
