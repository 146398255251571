import React from "react";
import { Helmet } from "react-helmet";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "../layout";
import PageHeader from "../page-header";

import QuickStart from "./quickstart";
import Environments from "../env/list";
import Clusters from "../cluster";
import Packages from "../pkg";
import { useOrg } from "../org/context";
import { startCase } from "lodash";
import { FadeIn } from "../skeleton/fade-in";

const DashboardPage = ({ org, location }) => {
	const classes = useStyles();
	const theOrg = useOrg(org);

	const orgName = theOrg?.name || startCase(org || "");

	return (
		<Layout org={org} location={location}>
			<Helmet>
				<title>{`${orgName} Dashboard`}</title>
			</Helmet>

			<FadeIn>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<QuickStart org={org}>
							<PageHeader>{orgName || "Your"} Dashboard</PageHeader>
						</QuickStart>
					</Grid>
					<Grid item xs={12} md={6} className={classes.sameHeightGrid}>
						<Environments org={org} className={classes.sameHeightCard} />
					</Grid>
					<Grid item xs={12} md={6} className={classes.sameHeightGrid}>
						<Clusters org={org} className={classes.sameHeightCard} />
					</Grid>

					<Grid item xs={12}>
						<Packages org={org} />
					</Grid>
				</Grid>
			</FadeIn>
		</Layout>
	);
};

const useStyles = makeStyles({
	sameHeightGrid: {
		display: "flex"
	},
	sameHeightCard: {
		width: "100%"
	}
});

export default DashboardPage;
