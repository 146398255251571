import React, { useMemo } from "react";
import {
	ButtonBase,
	MenuItem,
	Menu,
	Typography,
	Divider,
	ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";

import { Link, useMenu, belowPopProps } from "@runly/gatsby-theme";
import RefableLink from "../refable-link";

import { useOrgs } from "./context";

import { find } from "lodash";

const OrgChooser = ({ org: orgSlug, isBreadcrumb, className }) => {
	const orgs = useOrgs();

	const { buttonProps, menuProps, anchorEl, onClose } = useMenu();
	const classes = useStyles({ anchorEl });

	const org = useMemo(() => find(orgs, { id: orgSlug }), [orgSlug, orgs]);

	if (!orgSlug || !org) return null;

	if (isBreadcrumb) {
		return (
			<Link color="inherit" to={`/dashboard/${org.id}`}>
				<Typography variant="h6" component="h1">
					{org?.name}
				</Typography>
			</Link>
		);
	}

	return (
		<>
			<ButtonBase className={classes.button} {...buttonProps}>
				<Typography
					variant="h6"
					title="Choose Organization"
					className={className}
				>
					{org?.name}
				</Typography>
				<ArrowDropDown className={classes.dropdownIndicator} />
			</ButtonBase>
			<Menu {...belowPopProps} {...menuProps}>
				{orgs?.map(o => (
					<MenuItem
						key={o.id}
						value={o.id}
						component={RefableLink}
						to={`/dashboard/${o.id}`}
						display="block"
						onClick={onClose}
					>
						<ListItemText primary={o.name} secondary={o.id} />
					</MenuItem>
				))}
				<Divider />
				<MenuItem
					value="orgs/new"
					component={RefableLink}
					to="/dashboard/orgs/new"
				>
					Create New Org
				</MenuItem>
			</Menu>
		</>
	);
};

const useStyles = makeStyles(theme => {
	return {
		button: {},
		dropdownIndicator: {
			opacity: 0.5,
			transition: theme.transitions.create(["transform"]),
			transformOrigin: "50% 50%",
			transform: ({ anchorEl }) => `rotate(${anchorEl ? 180 : 0}deg)`
		}
	};
});

export default OrgChooser;
