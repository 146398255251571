import React from "react";

import { ReactComponent as ProdEnvIcon } from "../assets/env-prod.svg";
import { ReactComponent as DevEnvIcon } from "../assets/env-dev.svg";

const EnvIcon = ({ isProduction, ...props }) => {
	if (isProduction === undefined) return null;

	const WhichIcon = isProduction ? ProdEnvIcon : DevEnvIcon;
	return (
		<WhichIcon
			style={{ height: "1em" }}
			{...props}
			title={
				isProduction ? "Production environment" : "Development environment"
			}
		/>
	);
};

export default EnvIcon;
