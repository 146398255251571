import React from "react";
import { Box, Typography } from "@material-ui/core";
import PaperTip from "../paper-tip";
import PkgVersionCard from "./card";

const PkgVersionInline = ({ org, version, pkgId }) => {
	const tooltipContents = (
		<div>
			<PkgVersionCard {...{ org, version, pkgId }} />
		</div>
	);

	return (
		<PaperTip title={tooltipContents}>
			<Box
				display="inline-flex"
				borderColor="text.hint"
				border={1}
				px={0.5}
				py={0.125}
				mr={1}
				mb={1}
			>
				<Typography variant="caption">v{version}</Typography>
			</Box>
		</PaperTip>
	);
};

export default PkgVersionInline;
