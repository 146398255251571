const uploadReducer = (state, action) => {
	if (action.type === uploadAction.ENQUEUE_FILES) {
		const result = { ...state };

		action.payload.forEach(file => {
			result[file.name] = {
				file,
				bytesTotal: file.size,
				status: uploadStatus.QUEUED,
				request: null,
				progress: 0
			};
		});
		return result;
	}

	if (action.type === uploadAction.REQUEUE) {
		const existing = state[action.payload.fileName];
		return {
			...state,
			[action.payload.fileName]: {
				...existing,
				status: uploadStatus.QUEUED,
				request: null,
				progress: 0
			}
		};
	}

	if (action.type === uploadAction.TRANSFER_START) {
		const existing = state[action.payload.fileName];
		return {
			...state,
			[action.payload.fileName]: {
				...existing,
				request: action.payload.request,
				status: uploadStatus.TRANSFERRING
			}
		};
	}

	if (action.type === uploadAction.UPLOAD_CANCEL) {
		// eslint-disable-next-line no-unused-vars
		const { [action.payload.fileName]: _omitted, ...result } = state;
		return result;
	}

	if (action.type === uploadAction.UPLOAD_PROGRESS) {
		const existing = state[action.payload.fileName];
		const { bytesDone, bytesTotal } = action.payload;
		const progress = Math.min(
			100,
			Math.floor((bytesDone / bytesTotal) * 1e3) / 10
		);
		// load event can fire before progress event
		if (existing?.status !== uploadStatus.DONE) {
			return {
				...state,
				[action.payload.fileName]: {
					...existing,
					bytesDone,
					bytesTotal,
					progress,
					status:
						action.payload.progress === 100
							? uploadStatus.DONE
							: uploadStatus.TRANSFERRING
				}
			};
		}
	}
	if (action.type === uploadAction.UPLOAD_DONE) {
		return {
			...state,
			[action.payload.fileName]: {
				...action.payload.response,
				status: uploadStatus.DONE,
				progress: null
			}
		};
	}

	if (action.type === uploadAction.UPLOAD_ERROR) {
		return {
			...state,
			[action.payload.fileName]: {
				...state[action.payload.fileName],
				...action.payload.response,
				status: uploadStatus.ERROR,
				bytesDone: 0,
				progress: 0
			}
		};
	}

	return state;
};

export const uploadStatus = {
	QUEUED: "QUEUED",
	TRANSFERRING: "TRANSFERRING",
	ERROR: "ERROR",
	DONE: "DONE"
};

export const uploadAction = {
	REQUEUE: "REQUEUE",
	ENQUEUE_FILES: "ENQUEUE_FILES",
	TRANSFER_START: "TRANSFER_START",
	UPLOAD_PROGRESS: "UPLOAD_PROGRESS",
	UPLOAD_ERROR: "UPLOAD_ERROR",
	UPLOAD_DONE: "UPLOAD_DONE",
	UPLOAD_CANCEL: "UPLOAD_CANCEL"
};

export default uploadReducer;
