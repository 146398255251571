import { useOrgs } from "./context";

const OWNER = "Owner";
const OPS = "Ops";

const useOrgRole = org => {
	const orgs = useOrgs();
	org = orgs?.find(o => o.id == org);
	return org?.role;
};

export const useCanEditOrg = org => {
	const role = useOrgRole(org);
	return role == OWNER;
};

export const useCanEditEnv = org => {
	const role = useOrgRole(org);
	return role == OWNER || role == OPS;
};

export const useCanEditCluster = org => {
	const role = useOrgRole(org);
	return role == OWNER || role == OPS;
};

export const useCanQueue = (org, isProduction) => {
	const role = useOrgRole(org);
	return role == OWNER || role == OPS || isProduction === false;
};
