import React from "react";

import clsx from "clsx";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const PageHeader = ({ children, className, ...props }) => {
	const classes = useHeaderStyles();

	return (
		<Box mb={3}>
			<Typography
				variant="h3"
				component="h1"
				className={clsx(classes.typo, className)}
				{...props}
			>
				{children}
			</Typography>
		</Box>
	);
};

const useHeaderStyles = makeStyles({
	typo: {
		width: "100%"
	}
});

export const HeaderIcon = ({ children }) => {
	const classes = useIconStyles();
	return <div className={classes.container}>{children} </div>;
};

const useIconStyles = makeStyles({
	container: {
		display: "inline-flex",
		verticalAlign: "middle"
	}
});

export default PageHeader;
