import React from "react";
import { SvgIcon } from "@material-ui/core";
import { mdiPackage, mdiNpm } from "@mdi/js";

const PackageIcon = ({ type, ...props }) => {
	const npmIcon = <path d={mdiNpm} />;

	const nugetIcon = (
		<g>
			<path
				className="st0"
				d="M17.8,4.7h-6.7C7.8,4.7,5,7.4,5,10.8v6.7c0,3.4,2.8,6.2,6.2,6.2h6.7c3.4,0,6.2-2.8,6.2-6.2v-6.7
		C24,7.4,21.2,4.7,17.8,4.7z M9.7,12.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S11.1,12.5,9.7,12.5z M17.7,21.6
		c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4S19.9,21.6,17.7,21.6L17.7,21.6z"
			/>
			<path
				className="st0"
				d="M4,2.3c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2s0.9-2,2-2S4,1.2,4,2.3"
			/>
		</g>
	);

	const genericIcon = <path d={mdiPackage} />;
	return (
		<SvgIcon titleAccess={type} {...props}>
			{type
				? type === "Npm"
					? npmIcon
					: type === "Nuget"
					? nugetIcon
					: genericIcon
				: genericIcon}
		</SvgIcon>
	);
};

export default PackageIcon;
