import React from "react";
import { LinearProgress } from "@material-ui/core";
import { sumBy } from "lodash";
import { uploadStatus } from "../reducer";
import { makeStyles } from "@material-ui/core/styles";

import normalizeToPercent from "../../normalize-to-percent";

const TotalUploadProgress = ({ uploads }) => {
	const classes = useStyles();
	const uploadsArr = Object.values(uploads);

	const totalDone = sumBy(uploadsArr, "bytesDone");
	const totalError = sumBy(
		uploadsArr.filter(u => u.status === uploadStatus.ERROR),
		"bytesTotal"
	);
	const totalSize = sumBy(uploadsArr, "bytesTotal");

	const totalProgress = normalizeToPercent({
		value: totalDone,
		max: totalSize
	});
	const totalErrorProgress = normalizeToPercent({
		value: totalError,
		max: totalSize
	});
	if (totalSize) {
		return (
			<LinearProgress
				variant="buffer"
				value={totalProgress}
				valueBuffer={totalProgress + totalErrorProgress}
				classes={classes}
			/>
		);
	}
	return null;
};

const useStyles = makeStyles(theme => {
	return {
		root: { height: theme.spacing(1) },
		bar2Buffer: { backgroundColor: theme.palette.error.main }
	};
});

export default TotalUploadProgress;
