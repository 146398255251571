import React from "react";
import { IconButton, Menu } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { useMenu, belowPopProps } from "@runly/gatsby-theme";

const MoreMenu = ({ IconButtonProps = {}, children, MenuProps = {} }) => {
	const { buttonProps, menuProps, onClose } = useMenu();
	const {
		size = "medium",
		title = "More options",
		...iconButtonRest
	} = IconButtonProps;
	return (
		<>
			<IconButton
				{...buttonProps}
				{...iconButtonRest}
				size={size}
				title={title}
				aria-label="title"
				edge="end"
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
				{...belowPopProps}
				{...menuProps}
				{...MenuProps}
				onClick={onClose}
			>
				{children}
			</Menu>
		</>
	);
};

export default MoreMenu;
