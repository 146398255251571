import { useFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

export const useFetchPkgVersion = ({ org, pkgId, version }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const activeRunsReq = useFetch(
		url && bearerToken
			? `${url}/${org}/packages/${pkgId}/versions/${version}/`
			: null,
		{
			method: "GET",
			bearerToken
		}
	);

	return activeRunsReq;
};

export const usePackageDownloadUrl = (org, id, version) => {
	const { url, token } = useRunlyConfig();

	if (!org || !id || !version) {
		console.warn(
			`Package download requires ${[
				{ l: "org", v: org },
				{ l: "id", v: id },
				{ l: "version", v: version }
			]
				.filter(i => !i.v)
				.map(i => i.l)
				.join(", ")}`
		);
	}

	if (!token) return null;

	return `${url}/${org}/packages/${id}/${version}?access_token=${token}`;
};
