import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { LogoIcon } from "@runly/gatsby-theme";

const BackgroundArrows = () => {
	const steps = [0, 1, 2];
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.root}>
			{steps.map(idx => (
				<LogoIcon
					key={idx}
					className={classes.icon}
					style={{
						opacity: 0.05 + idx * 0.05,
						transform: `translateX(${idx * theme.spacing(-16)}px)`
					}}
				/>
			))}
		</div>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		position: "relative"
	},
	icon: {
		position: "absolute",
		top: 0,
		right: 0,
		width: theme.spacing(64),
		height: theme.spacing(64),
		fill: theme.palette.primary.main
	}
}));

export default BackgroundArrows;
