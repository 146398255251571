import React, { forwardRef } from "react";

import { Link, RootRef } from "@material-ui/core";

const DocLink = ({ children, href, ...props }, ref) => (
	<RootRef rootRef={ref}>
		<Link href={`/docs${href}`} target="_blank" {...props}>
			{children}
		</Link>
	</RootRef>
);

export default forwardRef(DocLink);
