import React from "react";
import { Box, Link } from "@material-ui/core";
import ProgressText from "./progress-text";
import { makeStyles } from "@material-ui/core/styles";
import PkgVersion from "../../../pkg-version";

const SecondaryText = ({
	org,
	id,
	isTransferring,
	progress,
	isError,
	message,
	helpLink,
	version,
	versions
}) => {
	const classes = useStyles();
	return isTransferring ? (
		progress === 0 ? (
			<>Waiting to upload…</>
		) : (
			<>
				<Box pr={1}>Uploading:</Box> <ProgressText progress={progress} />
			</>
		)
	) : isError ? (
		message ? (
			<>
				<Box pr={1}>{message}</Box> <Link href={helpLink}>Learn more</Link>
			</>
		) : (
			<>Error</>
		)
	) : versions?.length || version ? (
		<div className={classes.versions}>
			{(versions || [version]).map(v => (
				<PkgVersion org={org} pkgId={id} variant="Inline" key={v} version={v} />
			))}
		</div>
	) : null;
};

const useStyles = makeStyles(theme => {
	return {
		versions: {
			display: "flex",
			flexFlow: "wrap",
			alignItems: "center",
			margin: theme.spacing(-0.5, -1, 0, -1)
		}
	};
});

export default SecondaryText;
