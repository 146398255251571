import React from "react";
import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import getUserRoute from "./get-user-route";
import UserProfileAvatar from "./avatar";
import RefableLink from "../refable-link";

import DateTime from "../time";

const UserProfileListItem = ({
	isFetched,
	org,
	userId,
	name = "User",
	nickname,
	picture,
	role = "Role",
	email = "email",
	invitedOn,
	isOwner = role.toLowerCase() === "owner",
	children,
	returnUrl,
	returnLabel,
	returnState,
	profileLink = getUserRoute({ org, userId }),
	button = !!profileLink,
	component = button ? RefableLink : "div",
	...props
}) => {
	const avatar = (
		<UserProfileAvatar {...{ isFetched, isOwner, picture, nickname }} />
	);

	return (
		<ListItem
			button={button}
			key={userId}
			component={component}
			to={profileLink}
			state={{ returnUrl, returnLabel, returnState }}
			display="flex"
			color="inherit"
			{...props}
		>
			<ListItemAvatar>{avatar}</ListItemAvatar>
			<ListItemText
				style={{ flexBasis: "33%" }}
				primary={userId ? name : email}
				secondary={
					userId ? (
						<>{email}</>
					) : (
						<>
							Invited <DateTime value={invitedOn} />
						</>
					)
				}
			/>
			<ListItemText style={{ flexBasis: "33%" }} primary={role} />
			{children}
		</ListItem>
	);
};

export default UserProfileListItem;
