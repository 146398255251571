import React, { useMemo } from "react";

import { Link } from "@runly/gatsby-theme";

import semver from "semver";

const LatestVersion = ({ org, pkgId, versions }) => {
	const latestVersion = useMemo(() => semver.maxSatisfying(versions, "*"), [
		versions
	]);

	if (!latestVersion) return null;

	if (org && pkgId) {
		return (
			<Link
				to={`/dashboard/${org}/pkg/${pkgId}/${latestVersion}/`}
				color="inherit"
			>
				v{latestVersion}
			</Link>
		);
	}

	return <>v{latestVersion}</>;
};

export default LatestVersion;
