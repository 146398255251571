import React from "react";
import { List, ListItem, ListItemText, Box } from "@material-ui/core";
import UserActivity from "./activity";
import DateTime from "../time";

const UserInfo = ({
	horizontal,
	org,
	userId,
	email,
	invitedBy,
	invitedOn,
	joinedOn
}) => (
	<Box clone display={horizontal ? "flex" : null}>
		<List disablePadding>
			{email ? (
				<ListItem>
					<ListItemText primary="Email" secondary={email} />
				</ListItem>
			) : null}
			{invitedBy && invitedBy !== userId ? (
				<ListItem>
					<ListItemText
						{...typoProps}
						primary="Invited by"
						secondary={
							<UserActivity verb="" org={org} by={invitedBy} at={invitedOn} />
						}
					/>
				</ListItem>
			) : null}
			{joinedOn ? (
				<ListItem>
					<ListItemText
						{...typoProps}
						primary="Joined"
						secondary={<DateTime value={joinedOn} />}
					/>
				</ListItem>
			) : null}
		</List>
	</Box>
);

const typoProps = {
	primaryTypographyProps: { component: "div" },
	secondaryTypographyProps: { component: "div" }
};

export default UserInfo;
