import { useCallback } from "react";
import { uploadAction } from "../../reducer";

const useHandleReadyState = ({ fileName, uploadDispatch }) =>
	useCallback(
		e => {
			const req = e.currentTarget;
			if (req.readyState === 4) {
				uploadDispatch({
					type:
						req.status < 400
							? uploadAction.UPLOAD_DONE
							: uploadAction.UPLOAD_ERROR,
					payload: { fileName, response: JSON.parse(req.response) }
				});
			}
		},
		[fileName, uploadDispatch]
	);

export default useHandleReadyState;
