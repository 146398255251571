import React from "react";

import {
	IconButton,
	Menu,
	MenuItem,
	Link,
	ListItemIcon,
	Tooltip
} from "@material-ui/core";
import {
	Help as HelpIcon,
	Person as PersonIcon,
	ContactSupport as ContactSupportIcon,
	MenuBook as MenuBookIcon
} from "@material-ui/icons";

import { useMenu, belowPopProps } from "@runly/gatsby-theme";
import DocLink from "../doc-link";

const HelpMenu = ({ className }) => {
	const { buttonProps, menuProps } = useMenu();

	return (
		<>
			<Tooltip title="Help">
				<IconButton className={className} {...buttonProps}>
					<HelpIcon />
				</IconButton>
			</Tooltip>

			<Menu id="help-menu" keepMounted {...belowPopProps} {...menuProps}>
				<MenuItem component={DocLink} href="/" title="Read the documentation">
					<ListItemIcon>
						<MenuBookIcon />
					</ListItemIcon>
					Docs
				</MenuItem>
				<MenuItem
					component={Link}
					href="https://stackoverflow.com/questions/ask?&tags=runly"
					target="_blank"
					title="Ask a question on Stack Overflow"
				>
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					Community
				</MenuItem>
				<MenuItem
					component={Link}
					href="/help/"
					target="_blank"
					title="Contact us directly"
				>
					<ListItemIcon>
						<ContactSupportIcon />
					</ListItemIcon>
					Contact Support
				</MenuItem>
			</Menu>
		</>
	);
};

export default HelpMenu;
