import React from "react";

import {
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SkeletonListItem = ({
	avatar = true,
	avatarSize = 40,
	featuredCol = false,
	secondaryAction = false,
	cols = 1,
	...props
}) => {
	const _cols = new Array(cols).fill("").map((_, i) => i);

	return (
		<ListItem {...props}>
			{avatar ? (
				<ListItemAvatar>
					<Skeleton variant="circle" width={avatarSize} height={avatarSize} />
				</ListItemAvatar>
			) : null}
			{_cols.map(i => (
				<ListItemText
					key={i}
					primary={
						<Skeleton height={featuredCol && i === 0 ? 24 : 12} width="80%" />
					}
					secondary={
						featuredCol && i === 0 ? null : <Skeleton height={12} width="70%" />
					}
				/>
			))}
			{secondaryAction ? (
				<ListItemSecondaryAction>
					<Skeleton width={avatarSize / 3} height={avatarSize} />
				</ListItemSecondaryAction>
			) : null}
		</ListItem>
	);
};

export const SkeletonList = ({
	size = 3,
	featuredCol = false,
	cols = 1,
	avatar = true,
	avatarSize = 40,
	secondaryAction = false,
	...props
}) => {
	const items = new Array(size).fill("").map((_, i) => i);

	return (
		<List {...props}>
			{items.map(i => (
				<SkeletonListItem
					key={i}
					featuredCol={featuredCol}
					cols={cols}
					avatar={avatar}
					avatarSize={avatarSize}
					secondaryAction={secondaryAction}
				/>
			))}
		</List>
	);
};
