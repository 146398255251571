import React from "react";
import NavTab from "./tab";
import { makeStyles } from "@material-ui/core/styles";

const NavTabs = ({ tabs, location, activeTab = null, ...rest }) => {
	const classes = useStyles();

	return (
		<div className={classes.root} {...rest}>
			<div className={classes.tabs}>
				{tabs.map((tab, idx) => (
					<NavTab
						key={tab.href}
						// Assuming for now that the first tab is the landing one
						isActive={
							activeTab
								? activeTab === tab.label
								: idx === 0
								? tab.href === location.pathname
								: location.pathname.startsWith(
										tab.href.includes("?") ? tab.href.split("?")[0] : tab.href
								  )
						}
						{...tab}
					/>
				))}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() => {
	return {
		root: {
			position: "relative"
		},
		tabs: {
			display: "flex"
		}
	};
});

export default NavTabs;
