import React from "react";
import {
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	ListItemIcon,
	IconButton
} from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { FeaturedListItemText } from "@runly/gatsby-theme";

import RefableLink from "../refable-link";

import { useCanEditEnv } from "../org/permissions";

import SlugList from "../slug/list";
import EnvIcon from "./icon";

const EnvListItem = ({ org, id, isProduction, clusters }) => {
	const classes = useItemStyles();

	const canEditEnv = useCanEditEnv(org);

	return (
		<ListItem
			button
			component={RefableLink}
			title={
				isProduction ? "Production environment" : "Development environment"
			}
			to={`/dashboard/${org}/env/${id}`}
			display="flex"
			className={classes.root}
			color="inherit"
		>
			<ListItemIcon>
				<EnvIcon {...{ isProduction }} style={{ height: "3em" }} />
			</ListItemIcon>
			<FeaturedListItemText className={classes.listColumn} primary={id} />
			<ListItemText
				className={classes.listColumn}
				secondary={
					<>
						On <SlugList items={clusters} />
					</>
				}
				secondaryTypographyProps={{ component: "div" }}
			/>
			{canEditEnv ? (
				<ListItemSecondaryAction>
					<IconButton
						edge="end"
						aria-label="settings"
						size="medium"
						component={RefableLink}
						to={`/dashboard/${org}/env/${id}/settings`}
						title="Environment Settings"
					>
						<SettingsIcon />
					</IconButton>
				</ListItemSecondaryAction>
			) : null}
		</ListItem>
	);
};

const useItemStyles = makeStyles(theme => {
	return {
		root: {
			flexWrap: "wrap"
		},
		listColumn: {
			flexBasis: "50%",
			[theme.breakpoints.up("md")]: {
				flexBasis: "30%"
			},
			"& a": {
				position: "relative",
				zIndex: 1
			}
		}
	};
});

export default EnvListItem;
