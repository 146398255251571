import React from "react";
import Pkg from "../list/pkg";

const UploadList = ({ uploads, org, uploadDispatch }) => {
	const uploadsArr = Object.keys(uploads).map(k => {
		return {
			...uploads[k],
			fileName: k
		};
	});
	return (
		<>
			{uploadsArr.map(pkg => {
				return (
					<Pkg
						org={org}
						key={pkg.fileName}
						{...pkg}
						uploadDispatch={uploadDispatch}
					/>
				);
			})}
		</>
	);
};

export default UploadList;
