import React from "react";
import tc from "tinycolor2";
import { makeStyles } from "@material-ui/core/styles";

const Divider = () => {
	const classes = useStyles();
	return <hr className={classes.sexyDivider} />;
};

const useStyles = makeStyles(theme => {
	const base = theme.palette.divider;
	const transparentBase = tc(base).setAlpha(0).toString();
	return {
		sexyDivider: {
			margin: theme.spacing(0.5, 0),
			height: 1,
			border: 0,
			background: `linear-gradient(to right, ${transparentBase} 0%, ${base} 5%, ${base} 95%, ${transparentBase} 100%)`
		}
	};
});

export default Divider;
