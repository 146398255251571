import { useCallback } from "react";
import { uploadAction } from "../../reducer";

const useHandleProgress = ({ fileName, uploadDispatch }) =>
	useCallback(
		e => {
			const bytesDone = e.position || e.loaded;
			const bytesTotal = e.totalSize || e.total;
			uploadDispatch({
				type: uploadAction.UPLOAD_PROGRESS,
				payload: { fileName, bytesDone, bytesTotal }
			});
		},
		[fileName, uploadDispatch]
	);

export default useHandleProgress;
