import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchAccount = () => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken ? `${url}/account` : null, {
		method: "GET",
		bearerToken
	});
};

export const useFetchOrgAccounts = () => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken ? `${url}/account/orgs/` : null, {
		method: "GET",
		bearerToken
	});
};

export const useUpdateOrgAccount = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/account/orgs/${org}` : null,
		{
			method: "POST",
			bearerToken
		}
	);
};

export const useUpdateAccount = () => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(url && bearerToken ? `${url}/account` : null, {
		method: "PATCH",
		bearerToken
	});
};
