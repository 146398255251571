import React from "react";
import { Box, Typography } from "@material-ui/core";

const EmptyMessage = ({ children }) => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center" p={4}>
			<Typography align="center" variant="body1" component="div">
				{children}
			</Typography>
		</Box>
	);
};

export default EmptyMessage;
