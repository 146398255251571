import React from "react";

import { Avatar, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAccount } from "../../account/context";
import ProfileMenu from "./menu";
import { useMenu } from "@runly/gatsby-theme";
import { Skeleton } from "@material-ui/lab";

const Profile = () => {
	const classes = useStyles();

	const user = useAccount();

	const { buttonProps, menuProps } = useMenu();

	return user ? (
		<>
			<Tooltip title={user.name} aria-label="User menu">
				<IconButton
					{...buttonProps}
					color="inherit"
					aria-controls="account-menu"
					aria-haspopup="true"
				>
					<Avatar
						className={classes.avatar}
						alt={user.name}
						src={user.picture}
					/>
				</IconButton>
			</Tooltip>
			<ProfileMenu user={user} {...menuProps} />
		</>
	) : (
		<IconButton disabled>
			<Skeleton variant="circle" className={classes.avatar} />
		</IconButton>
	);
};

const useStyles = makeStyles(theme => {
	return {
		avatar: {
			width: theme.spacing(3),
			height: theme.spacing(3)
		}
	};
});

export default Profile;
