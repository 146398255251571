import React from "react";
import { Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lightGreen, grey, amber } from "@material-ui/core/colors";

const calculateStatusColor = ({ isOnline, shutdownRequestedAt }) =>
	isOnline ? (shutdownRequestedAt ? amber.A700 : lightGreen.A700) : grey[700];

const useBadgeStyles = makeStyles(theme => ({
	root: { margin: 0 },
	badge: {
		zIndex: 2,
		backgroundColor: calculateStatusColor,
		color: calculateStatusColor,
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""'
		}
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0
		}
	}
}));

const useInnerStyles = makeStyles(theme => {
	return {
		root: {
			transition: theme.transitions.create(["opacity"]),
			opacity: ({ isOnline }) => (isOnline || isOnline === undefined ? 1 : 0.6)
		}
	};
});

const OnlineBadge = ({ isOnline, shutdownRequestedAt, children, ...props }) => {
	const title =
		isOnline && shutdownRequestedAt
			? "shutting down"
			: isOnline !== undefined
			? isOnline
				? "online"
				: "offline"
			: null;
	const badgeClasses = useBadgeStyles({ isOnline, shutdownRequestedAt });
	const innerClasses = useInnerStyles({ isOnline });

	return (
		<Badge
			classes={badgeClasses}
			invisible={!isOnline}
			overlap="circle"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			variant="dot"
			title={title}
			{...props}
		>
			<div className={innerClasses.root}>{children}</div>
		</Badge>
	);
};

export default OnlineBadge;
