import { useCallback } from "react";
import { uploadAction } from "../reducer";

function useHandleDrop({ uploadDispatch }) {
	return useCallback(
		acceptedFiles => {
			uploadDispatch({
				type: uploadAction.ENQUEUE_FILES,
				payload: acceptedFiles
			});
		},
		[uploadDispatch]
	);
}

export default useHandleDrop;
