import { useEffect } from "react";
import { useRunlyConfig } from "@runly/ui";
import { uploadAction, uploadStatus } from "../../reducer";

const useQueuePackage = ({
	file,
	uploadDispatch,
	handleProgress,
	handleReadyState,
	status,
	org
}) => {
	const { url, token } = useRunlyConfig();

	useEffect(() => {
		if (status === uploadStatus.QUEUED) {
			const formPayload = new FormData();
			formPayload.append("file", file, file.name);
			if (token) {
				const request = new XMLHttpRequest();
				uploadDispatch({
					type: uploadAction.TRANSFER_START,
					payload: { fileName: file.name, request }
				});
				request.upload.onprogress = handleProgress;
				request.onreadystatechange = handleReadyState;
				request.open("PUT", `${url}/${org}/packages`);
				request.setRequestHeader("Authorization", `Bearer ${token}`);
				request.send(formPayload);
			}
		}
	}, [
		file,
		handleReadyState,
		handleProgress,
		org,
		token,
		uploadDispatch,
		status,
		url
	]);
};

export default useQueuePackage;
