import { useFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchQuickStart = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && org ? `${url}/${org}/quickstart` : null,
		{
			method: "GET",
			bearerToken
		}
	);
};
