import React, { useMemo } from "react";

import { Card, CardHeader, List, ListItemText } from "@material-ui/core";
import { Button } from "@runly/gatsby-theme";

import { sortBy } from "lodash";

import FakeSuspense from "../fake-suspense";
import EmptyMessage from "../empty-message";
import AddFab from "../add-fab";
import SlugList from "../slug/list";

import { useCanEditCluster } from "../org/permissions";

import { useFetchClusters } from "./api";
import ClusterItem, { useClusterItemStyles } from "./item";
import { SkeletonList } from "../skeleton/list";
import { FadeIn } from "../skeleton/fade-in";

const Clusters = ({ org, ...props }) => {
	const clustersRequest = useFetchClusters({ org });
	const canEditCluster = useCanEditCluster(org);

	return (
		<Card {...props}>
			<CardHeader
				title="Clusters"
				action={
					canEditCluster ? (
						<AddFab
							title="Add New Cluster"
							to={`/dashboard/${org}/clusters/new`}
						/>
					) : null
				}
			/>

			<FakeSuspense
				req={clustersRequest}
				description="clusters"
				emptyChildren={<NoClusters canEditCluster={canEditCluster} org={org} />}
				placeholder={
					<FadeIn>
						<SkeletonList featuredCol cols={2} secondaryAction />
					</FadeIn>
				}
			>
				<ClusterList org={org} clusters={clustersRequest.body} />
			</FakeSuspense>
		</Card>
	);
};

const ClusterList = ({ org, clusters }) => {
	clusters = useMemo(() => sortBy(clusters, "id"), [clusters]);
	const classes = useClusterItemStyles();

	return (
		<List data-testid="cluster-list">
			{clusters.map(cluster => {
				return (
					<ClusterItem key={cluster.id} org={org} cluster={cluster}>
						<ListItemText
							className={classes.col}
							secondary={
								<>
									Hosting{" "}
									<SlugList items={cluster.environments.map(e => e.id)} />
								</>
							}
							secondaryTypographyProps={{ component: "div" }}
						/>
					</ClusterItem>
				);
			})}
		</List>
	);
};

const NoClusters = ({ org, canEditCluster }) => (
	<EmptyMessage>
		You have not created any clusters.
		{canEditCluster ? (
			<>
				<br />
				<Button to={`/dashboard/${org}/clusters/new`} color="primary">
					Create Cluster
				</Button>
			</>
		) : null}
	</EmptyMessage>
);

export default Clusters;
