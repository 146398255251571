import React, { useReducer } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Card, CardHeader, Link, Grid } from "@material-ui/core";

import { useDropzone } from "react-dropzone";

import EmptyMessage from "../empty-message";
import FakeSuspense from "../fake-suspense";
import DocLink from "../doc-link";

import { useFetchPackages } from "./api";
import PkgList from "./list";
import UploadPrompt from "./upload";
import useHandleDrop from "./upload/use-handle-drop";
import uploadReducer from "./reducer";
import UploadList from "./upload/list";
import TotalUploadProgress from "./list/progress";
import { SkeletonList } from "../skeleton/list";
import { FadeIn } from "../skeleton/fade-in";
import { isEmpty } from "lodash";

const MEBIBYTE_IN_BYTES = Math.pow(1024, 2);
const PACKAGE_MAX_FILE_SIZE = MEBIBYTE_IN_BYTES * 100;

const PkgRoot = ({ org }) => {
	const pkgsRequest = useFetchPackages(org);
	const [uploadState, uploadDispatch] = useReducer(uploadReducer, {});
	const handleDrop = useHandleDrop({ org, uploadDispatch });

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		draggedFiles
	} = useDropzone({
		maxSize: PACKAGE_MAX_FILE_SIZE,
		onDrop: handleDrop
	});
	const { onClick: onDropzoneClick, ...rootPropsRest } = getRootProps();

	return (
		<div {...rootPropsRest}>
			<Card>
				<CardHeader title="Packages" />

				<Grid container direction="row-reverse">
					<Grid item xs={12} md={6}>
						<UploadPrompt
							{...{
								uploadState,
								onDropzoneClick,
								getInputProps,
								isDragActive,
								draggedFiles
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{uploadState ? (
							<>
								<TotalUploadProgress uploads={uploadState} />
								<UploadList
									{...{
										uploads: uploadState,
										org,
										uploadDispatch
									}}
								/>
							</>
						) : null}

						<FakeSuspense
							req={pkgsRequest}
							description="packages"
							// TODO: Merge fetch & upload states into one reducer.
							// consider using `useRemoteCollection`.
							emptyChildren={isEmpty(uploadState) ? <NoPackages /> : null}
							placeholder={
								<FadeIn>
									<SkeletonList featuredCol />
								</FadeIn>
							}
						>
							<PkgList pkgs={pkgsRequest.body} org={org} />
						</FakeSuspense>
					</Grid>
				</Grid>
			</Card>
		</div>
	);
};

const NoPackages = () => {
	const {
		site: {
			siteMetadata: { exampleRepo }
		}
	} = useStaticQuery(graphql`
		query NoPackages {
			site {
				siteMetadata {
					exampleRepo
				}
			}
		}
	`);

	return (
		<EmptyMessage>
			You have not uploaded any packages.
			<br />
			<DocLink href="/getting-started/package/">
				Learn how to create a package
			</DocLink>{" "}
			or upload the{" "}
			<Link href={exampleRepo} target="_blank">
				Examples package
			</Link>{" "}
			to start running jobs right away.
		</EmptyMessage>
	);
};

export default PkgRoot;
