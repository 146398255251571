import React, { forwardRef } from "react";
import { Link } from "@runly/gatsby-theme";

// HACK to wrap gatsby link when used as underlying component
// in MUI components as they expect to put a ref on a native element.
// We could use the built-in `RootRef` wrapper but that depends on
// the deprecated `findDOMNode` API.

const _RefableLink = (
	{
		component: Component = "div",
		display = "inline-block",
		underline = "none",
		wrapperProps = { style: { display } },
		...props
	},
	ref
) => (
	<Component {...wrapperProps} ref={ref} {...wrapperProps}>
		<Link underline={underline} {...props} />
	</Component>
);
const RefableLink = forwardRef(_RefableLink);

export default RefableLink;
