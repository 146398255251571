import React, { useEffect, useState, useCallback } from "react";
import Notification from "./notification";
import { usePrevious } from "./use-previous";
import { Button } from "@material-ui/core";

const ErrorMessage = ({
	req,
	verb = "loading",
	description,
	title,
	action
}) => {
	const messageId =
		(req.error && (req.headers?.Date || req.headers?.date)) || undefined;
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [isDismissed, setIsDismissed] = useState(false);

	const prevMessageId = usePrevious(messageId);

	const showNotification = useCallback(() => {
		setIsNotificationOpen(true);
	}, []);
	const dismissNotification = useCallback(() => {
		setIsNotificationOpen(false);
		setIsDismissed(true);
	}, []);

	useEffect(() => {
		if (!isDismissed && req.error) {
			showNotification();
		}
	}, [isDismissed, messageId, prevMessageId, req.error, showNotification]);

	useEffect(() => {
		if (messageId !== prevMessageId) {
			setIsDismissed(false);
		}
	}, [messageId, prevMessageId]);

	const _action = req.body?.helpLink
		? [
				<Button key="help-link" href={req.body.helpLink} color="inherit">
					Learn more
				</Button>
		  ]
		: [];

	if (req.error) {
		const fallbackMessage = (
			<>
				Problem {verb} {description}
			</>
		);
		return (
			<Notification
				severity="error"
				messageId={messageId}
				isOpen={isNotificationOpen}
				handleClose={dismissNotification}
				action={[action, _action]}
				title={title || fallbackMessage || "Error"}
			>
				<>
					{req.error?.message &&
					req.error.message.toLowerCase() !== "failed to fetch"
						? req.error.message
						: fallbackMessage}
				</>
			</Notification>
		);
	}
	return null;
};

export default ErrorMessage;
