import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchEnvironments = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && org ? `${url}/${org}/environments/` : null,
		{
			method: "GET",
			bearerToken
		}
	);
};

export const useFetchEnvironment = (org, env) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken ? `${url}/${org}/environments/${env}/` : null,
		{
			method: "GET",
			bearerToken
		}
	);
};

export const useUpdateEnvironment = (org, envId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/environments/${envId}/` : null,
		{
			method: "PATCH",
			bearerToken
		}
	);
};

export const useDeleteEnvironment = (org, env) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/environments/${env}` : null,
		{
			method: "DELETE",
			bearerToken
		}
	);
};

export const useSaveNewEnvironment = (org, env) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/environments/` : null,
		{
			method: "POST",
			bearerToken,
			body: JSON.stringify(env)
		}
	);
};
