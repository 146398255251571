import React from "react";
import UserProfile from ".";
import DateTime from "../time";

const UserActivity = ({ at, by, verb = "Created" }) => {
	const needsLoad = typeof by === "string";
	if (!at) {
		return <>Never {verb}</>;
	}

	return (
		<>
			{by ? (
				<UserProfile
					userId={needsLoad ? by : null}
					{...(needsLoad ? {} : by)}
					returnLabel="Environment Overview"
				/>
			) : null}{" "}
			{verb}{" "}
			{at !== false ? (
				<span style={{ display: "inline-block", opacity: 0.8 }}>
					<DateTime value={at} />
				</span>
			) : null}
		</>
	);
};

export default UserActivity;
