import { useMemo } from "react";
import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchOrganization = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken ? `${url}/${org}/` : null, {
		method: "GET",
		bearerToken
	});
};

export const useFetchOrgLimits = org => {
	const { body, ...req } = useFetchOrganization(org);

	const result = useMemo(
		() => ({
			body: body?.limits,
			...req
		}),
		[body, req]
	);

	return result;
};

export const useFetchOrgUsage = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken ? `${url}/${org}/usage` : null, {
		method: "GET",
		bearerToken
	});
};

export const useTimeZone = () =>
	Intl?.DateTimeFormat?.().resolvedOptions?.()?.timeZone;

export const useCreateDefaultOrg = shouldDoIt => {
	const { url, token: bearerToken } = useRunlyConfig();
	const timeZone = useTimeZone();

	return useFetch(shouldDoIt && url && bearerToken ? `${url}/orgs/` : null, {
		method: "POST",
		bearerToken,
		body: JSON.stringify({ timeZone })
	});
};

export const useUpdateOrg = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(url && bearerToken ? `${url}/${org}/` : null, {
		method: "PATCH",
		bearerToken
	});
};
