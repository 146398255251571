import React from "react";
import { lightGreen } from "@material-ui/core/colors";
import { usePrevious } from "../../use-previous";
import { makeStyles } from "@material-ui/core/styles";
import tc from "tinycolor2";

const useStyles = makeStyles(theme => {
	const isDark = theme.palette.type === "dark";
	return {
		"@keyframes highlight": {
			"0%": {
				boxShadow: `0 0 1px 0 ${theme.palette.text.primary}`
			},
			"100%": {
				boxShadow: `0 0 10px 5px ${theme.palette.text.hint}`
			}
		},
		square: {
			border: `1px solid ${tc(isDark ? lightGreen["A700"] : lightGreen[900])
				.setAlpha(0.5)
				.toString()}`,
			margin: 1,
			width: ({ size }) => size,
			height: ({ size }) => size,
			background: ({ isOnline }) =>
				isOnline
					? lightGreen["A400"]
					: tc(lightGreen[900]).setAlpha(0.5).toString(),
			animation: ({ cameOnline }) =>
				cameOnline ? "$highlight 0.5s ease-in-out" : null
		}
	};
});

const NodeGridItem = ({ isOnline = false, size = 4 }) => {
	const prevOnline = usePrevious(isOnline);
	const cameOnline = prevOnline === false && isOnline;
	const classes = useStyles({ size, isOnline, cameOnline });
	return <div className={classes.square} />;
};

export default NodeGridItem;
