import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import RefableLink from "../refable-link";

const SlugList = ({ items, getLink, truncate, ...props }) => {
	const classes = useStyles({ truncate });

	if (!items || !items.length) return <>None</>;

	return (
		<ul className={classes.list} {...props}>
			{items.map(id => (
				<li key={id}>
					<SlugItem value={id} getLink={getLink} />
				</li>
			))}
		</ul>
	);
};

const useSlugLinkStyles = makeStyles(theme => {
	return {
		slugLink: {
			color: "inherit",
			"&:hover, &:focus": {
				color: theme.palette.text.primary
			}
		}
	};
});

const SlugItem = ({ value, getLink }) => {
	const url = getLink ? getLink(value) : null;

	const classes = useSlugLinkStyles();

	if (url) {
		return (
			<RefableLink to={url} color="inherit" className={classes.slugLink}>
				{value}
			</RefableLink>
		);
	}

	return value;
};

const useStyles = makeStyles(() => ({
	list: {
		display: ({ truncate }) => (truncate ? "block" : "inline"),
		width: ({ truncate }) => (truncate ? "100%" : undefined),
		overflowX: ({ truncate }) => (truncate ? "hidden" : undefined),
		textOverflow: ({ truncate }) => (truncate ? "ellipsis" : undefined),
		whiteSpace: ({ truncate }) => (truncate ? "nowrap" : undefined),
		listStyle: "none",
		margin: 0,
		padding: 0,
		"& li": {
			display: "inline",

			"&:after": {
				content: '", "'
			},

			"&:last-child:after": {
				content: '""'
			}
		}
	}
}));

export default SlugList;
