import React from "react";
import { Card, CardHeader } from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";
import getUserRoute from "./get-user-route";
import UserProfileAvatar from "./avatar";
import UserInfo from "./info";

const UserProfileCard = props => {
	const { returnUrl, returnLabel, returnState, ...rest } = props;
	const {
		isFetched,
		org,
		userId,
		name = "User",
		nickname,
		picture,
		role = "Role",
		isOwner,
		baseElevation,
		cardProps = { elevation: Math.min(24, baseElevation + 4) }
	} = rest;
	const avatar = (
		<UserProfileAvatar {...{ isFetched, isOwner, picture, nickname }} />
	);

	return (
		<Card style={{ minWidth: 256 }} elevation={cardProps.elevation}>
			<CardHeader
				title={
					<Link
						color="textPrimary"
						to={getUserRoute({ org, userId })}
						state={{ returnUrl, returnLabel, returnState }}
					>
						{name}
					</Link>
				}
				subheader={role}
				avatar={avatar}
			/>
			<UserInfo {...rest} />
		</Card>
	);
};

export default UserProfileCard;
