import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const PaperTip = ({ children, title, ...props }) => {
	const { contents, ...classes } = useStyles();
	return (
		<Tooltip
			interactive
			classes={classes}
			enterDelay={500}
			leaveDelay={200}
			title={<div className={contents}>{title}</div>}
			{...props}
		>
			{children}
		</Tooltip>
	);
};

const useStyles = makeStyles(theme => {
	return {
		tooltip: {
			borderRadius: 0,
			margin: 0,
			padding: 0,
			background: "none",
			fontSize: theme.typography.body1.fontSize,
			fontWeight: theme.typography.body1.fontWeight
		},
		contents: {
			opacity: 0.9,
			backdropFilter: "blur(5px)"
		}
	};
});

export default PaperTip;
