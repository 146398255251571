import React from "react";
import {
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction
} from "@material-ui/core";

import { FeaturedListItemText } from "@runly/gatsby-theme";

import { ReactComponent as ClusterIcon } from "../assets/cluster-material-system.svg";

import RefableLink from "../refable-link";
import ClusterCopyButton from "./copy-api-key";
import Time from "../time";
import ClusterNodeGrid from "./cluster-node-grid";
import OnlineBadge from "../online-badge";
import { makeStyles } from "@material-ui/core/styles";

export const EnvOverviewClusterInfo = ({ cluster }) => {
	const classes = useClusterItemStyles();
	return (
		<>
			<ListItemText className={classes.col}>
				<ClusterNodeGrid {...cluster.nodes} />
			</ListItemText>
			<ListItemText
				className={classes.col}
				primary={
					cluster.isOnline
						? "Uptime"
						: cluster.statusChangedAt
						? "Offline Since"
						: "Never Connected"
				}
				secondary={
					cluster.statusChangedAt ? (
						<Time
							value={cluster.statusChangedAt}
							addSuffix={!cluster.isOnline}
						/>
					) : null
				}
				secondaryTypographyProps={{ variant: "body1" }}
			/>
		</>
	);
};

const ClusterItem = ({
	org,
	cluster,
	children,
	ListItemProps: _ListItemProps = {}
}) => {
	const classes = useClusterItemStyles();

	const linkToCluster = `/dashboard/${org}/clusters/${cluster.id}`;

	const ListItemProps = {
		button: true,
		color: "inherit",
		to: linkToCluster,
		display: "flex",
		component: RefableLink,
		..._ListItemProps,
		className: classes.root
	};

	const showMenu = !!cluster.apiKey;

	return (
		<ListItem {...ListItemProps}>
			<ClusterAvatar {...cluster} />
			<FeaturedListItemText className={classes.col} primary={cluster.id} />
			{children}
			{showMenu ? (
				<ListItemSecondaryAction>
					<ClusterCopyButton {...{ cluster, linkToCluster }} />
				</ListItemSecondaryAction>
			) : null}
		</ListItem>
	);
};

export const useClusterItemStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap"
		},
		col: {
			flexBasis: "50%",
			flexShrink: 0,
			[theme.breakpoints.up("md")]: { flexBasis: "25%", flexShrink: 1 }
		}
	};
});

export default ClusterItem;

export const ClusterAvatar = ({ isOnline }) => {
	return (
		<ListItemIcon>
			<OnlineBadge isOnline={isOnline}>
				<ClusterIcon style={{ width: "3em", height: "3em" }} />
			</OnlineBadge>
		</ListItemIcon>
	);
};
