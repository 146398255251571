import React from "react";
import { Link } from "@runly/gatsby-theme";
import { Typography } from "@material-ui/core";

const PrimaryText = ({ isTransferring, isError, fileName, id, org }) => {
	return isTransferring || isError ? (
		fileName
	) : id ? (
		<Link color="textPrimary" to={`/dashboard/${org}/pkg/${id}/`}>
			<Typography>{id}</Typography>
		</Link>
	) : (
		fileName
	);
};

export default PrimaryText;
