import React, { useMemo } from "react";

import { List, ListItem, ListItemAvatar } from "@material-ui/core";
import { FeaturedListItemText } from "@runly/gatsby-theme";

import { Flipper, Flipped } from "react-flip-toolkit";
import { sortBy } from "lodash";

import RefableLink from "../../refable-link";
import PackageIcon from "../../assets/package-icon";

import LatestVersion from "../latest-version";

const PkgList = ({ pkgs, org, uploads }) => {
	pkgs = useMemo(() => sortBy(pkgs, "id"), [pkgs]);

	return (
		<>
			<Flipper
				flipKey={uploads ? Object.keys(uploads).join(",") : "uploads-empty"}
			>
				<Flipped flipId="uploadList">
					<List disablePadding>
						{pkgs.map(pkg => (
							<ListItem
								key={pkg.id}
								component={RefableLink}
								button
								color="inherit"
								display="block"
								to={`/dashboard/${org}/pkg/${pkg.id}/`}
							>
								<ListItemAvatar>
									<PackageIcon fontSize="large" type={pkg.type} />
								</ListItemAvatar>
								<FeaturedListItemText
									primary={pkg.id}
									secondary={<LatestVersion versions={pkg.versions} />}
								/>
							</ListItem>
						))}
					</List>
				</Flipped>
			</Flipper>
		</>
	);
};

export default PkgList;
