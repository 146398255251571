import React, { useState, createContext, useContext } from "react";

const UserProfilesContext = createContext();
export const Provider = props => {
	const [cache, setCache] = useState({});
	const [org, setOrg] = useState();

	return (
		<UserProfilesContext.Provider value={{ cache, setCache, org, setOrg }}>
			{props.children}
		</UserProfilesContext.Provider>
	);
};

const useUserProfiles = () => {
	return useContext(UserProfilesContext);
};

export default useUserProfiles;
