import React from "react";
import { Box, CardActionArea, CardContent, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Flipper, Flipped } from "react-flip-toolkit";
import DropzoneIndicator from "./drop-zone-indicator";

const UploadPrompt = ({
	uploadStatus,
	getInputProps,
	onDropzoneClick,
	isDragActive,
	draggedFiles
}) => {
	const classes = useStyles({ isDragActive });
	if (uploadStatus && uploadStatus !== "Done") {
		return <>{uploadStatus}</>;
	}
	return (
		<Flipper flipKey={isDragActive}>
			<>
				<input {...getInputProps()} />
				<CardActionArea
					className={classes.dropzoneClickzone}
					onClick={onDropzoneClick}
				>
					<CardContent className={classes.dropzoneClickInner}>
						<Box pb={3}>
							{isDragActive ? (
								<>Drop {draggedFiles.length} files here.</>
							) : (
								<>Drag a nupkg file here to upload.</>
							)}
						</Box>
						<Flipped flipId="dropzoneButton">
							<Fab
								onClick={() => {
									return true;
								}}
								component="div"
								size="medium"
								color="primary"
								variant="extended"
								className={
									isDragActive
										? classes.dropzoneButtonActive
										: classes.dropzoneButton
								}
							>
								<Flipped inverseFlipId="dropzoneButton">
									{isDragActive ? <div /> : <div>Browse for Packages</div>}
								</Flipped>
							</Fab>
						</Flipped>
					</CardContent>
				</CardActionArea>
				<DropzoneIndicator {...{ isDragActive }} />
			</>
		</Flipper>
	);
};

const useStyles = makeStyles(theme => {
	return {
		dropzoneClickzone: {
			textAlign: "center",
			width: "100%",
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			minHeight: theme.spacing(20)
		},
		dropzoneClickInner: {
			minHeight: theme.spacing(20)
		},
		dropzoneButton: {},
		dropzoneButtonActive: {
			opacity: 0,
			height: ["100%", "!important"],
			borderRadius: [0, "!important"],
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		}
	};
});

export default UploadPrompt;
