import React from "react";

import { Fab } from "@runly/gatsby-theme";
import { Add as AddIcon } from "@material-ui/icons";

const AddButton = props => (
	<Fab color="primary" aria-label="add" {...props}>
		<AddIcon />
	</Fab>
);

export default AddButton;
