import React from "react";
import { isEmpty, isFunction } from "lodash";
import EmptyMessage from "./empty-message";
import { LoadingIndicator } from "@runly/gatsby-theme";
import { makeStyles } from "@material-ui/core/styles";
import ErrorMessage from "./error-message";

const FakeSuspense = ({
	req,
	verb = "loading",
	description = "items",
	children,
	placeholder = null,
	dimOnFetch,
	emptyAction,
	emptyChildren = (
		<EmptyMessage>
			{
				<>
					No {description} to show.
					{emptyAction && <br />}
					{emptyAction}
				</>
			}
		</EmptyMessage>
	),
	errorChildren
}) => {
	const isPaginated = getIsPaginated(req);
	const classes = useStyles({ dimOnFetch, isPaginated, ...req });

	const body = isPaginated ? req.body.data : req.body;

	if (!req.error) {
		if (body && !isEmpty(body) && children) {
			const theChild = isFunction(children) ? children(body) : children;

			return <div className={classes.root}>{theChild}</div>;
		}

		if (req.isFetched && isEmpty(body) && !req.error) {
			return emptyChildren;
		}

		if (req.isFetching) {
			return placeholder ? (
				placeholder
			) : (
				<LoadingIndicator>Loading {description}…</LoadingIndicator>
			);
		}

		return placeholder;
	}

	return (
		<>
			{errorChildren}
			<ErrorMessage {...{ req, verb, description }} />
		</>
	);
};

export default FakeSuspense;

const useStyles = makeStyles(theme => {
	return {
		root: {
			transition: theme.transitions.create(["opacity"]),
			opacity: ({ dimOnFetch, isFetching, isPaginated }) =>
				(isPaginated || dimOnFetch) && isFetching ? 0.5 : 1
		}
	};
});

const getIsPaginated = req =>
	req?.body && "data" in req.body && "hasMore" in req.body;
