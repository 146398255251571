import React from "react";
import { Avatar, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { VerifiedUser } from "@material-ui/icons";

const UserProfileAvatar = ({ isOwner, nickname, picture }) => {
	const classes = useStyles();
	return (
		<Badge
			overlap="circle"
			badgeContent={
				isOwner ? <VerifiedUser className={classes.ownerBadgeIcon} /> : null
			}
		>
			<Avatar alt={nickname} src={picture} />
		</Badge>
	);
};

export default UserProfileAvatar;

const useStyles = makeStyles(theme => {
	return {
		ownerBadgeIcon: {
			fontSize: theme.spacing(2),
			stroke: theme.palette.background.paper
		}
	};
});
