import React, { useState, useEffect } from "react";
import { Fade } from "@material-ui/core";

export const FadeIn = ({ children, timeout = 500 }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
		return () => {
			setShow(false);
		};
	}, []);

	return (
		<Fade in={show} timeout={timeout}>
			{children}
		</Fade>
	);
};
