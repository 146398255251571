import React from "react";
import NodeGridItem from "./grid-item";
import { chunk } from "lodash";

const ClusterNodeGrid = ({ online, disabled, maxShortEdge = 36 }) => {
	const total = online + disabled;
	if (total === 0) return null;

	const oArr = new Array(online)
		.fill()
		.map((_, i) => ({ id: i, isOnline: true }));
	const dArr = new Array(disabled)
		.fill()
		.map((_, i) => ({ id: i + online, isOnline: false }));

	const tArr = [...oArr, ...dArr];

	const chunkSize = Math.ceil(Math.sqrt(total));
	const chunkz = chunk(tArr, chunkSize);

	const size = maxShortEdge / Math.max(chunkSize - (chunkz.length - 1), 2);

	let idx = 0;
	return (
		<div title={`nodes: ${online} online, ${disabled} disabled`}>
			{chunkz.map(c => {
				const k = idx;
				idx += 1;
				return (
					<div key={k} style={{ display: "flex" }}>
						{c.map(n => (
							<NodeGridItem size={size} key={n.id} {...n} />
						))}
					</div>
				);
			})}
		</div>
	);
};

export default ClusterNodeGrid;
