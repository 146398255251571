import React, { useMemo } from "react";

import { List, Card, CardHeader } from "@material-ui/core";
import { Button } from "@runly/gatsby-theme";

import { useFetchEnvironments } from "./api";
import FakeSuspense from "../fake-suspense";
import EmptyMessage from "../empty-message";

import EnvListItem from "./list-item";

import { orderBy } from "lodash";

import AddFab from "../add-fab";

import { useCanEditEnv } from "../org/permissions";
import { SkeletonList } from "../skeleton/list";
import { FadeIn } from "../skeleton/fade-in";

const EnvironmentsList = ({ org, ...props }) => {
	const environmentsRequest = useFetchEnvironments(org);

	const envs = useMemo(() => {
		const envs = environmentsRequest.body;
		if (!envs || !envs.length) return [];
		return orderBy(envs, ["isProduction", "id"], ["desc", "asc"]);
	}, [environmentsRequest.body]);

	const canEditEnv = useCanEditEnv(org);

	return (
		<Card {...props}>
			<CardHeader
				title="Environments"
				action={
					canEditEnv ? (
						<AddFab title="Add Environment" to={`/dashboard/${org}/env/new`} />
					) : null
				}
			/>

			<FakeSuspense
				req={environmentsRequest}
				description="environments"
				emptyChildren={<NoEnvironments org={org} canEditEnv={canEditEnv} />}
				placeholder={
					<FadeIn>
						<SkeletonList featuredCol cols={2} secondaryAction />
					</FadeIn>
				}
			>
				<List>
					{envs.map(env => (
						<EnvListItem key={env.id} org={org} {...env} />
					))}
				</List>
			</FakeSuspense>
		</Card>
	);
};

const NoEnvironments = ({ org, canEditEnv }) => (
	<EmptyMessage>
		You have not created any environments.
		{canEditEnv ? (
			<>
				<br />
				<Button to={`/dashboard/${org}/env/new`} color="primary">
					Create Environment
				</Button>
			</>
		) : null}
	</EmptyMessage>
);

export default EnvironmentsList;
