import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import tc from "tinycolor2";

const DropzoneIndicator = ({ isDragActive }) => {
	const classes = useStyles({ isDragActive });
	return (
		<div
			className={`${classes.base} ${
				isDragActive ? classes.active : classes.inactive
			}`}
		/>
	);
};

const useStyles = makeStyles(theme => {
	const u = theme.spacing(1);
	const { secondary } = theme.palette;
	const { duration } = theme.transitions;

	const borderWidth = u / 2;
	const dashLength = u * 4;
	const dashAnimDistance = dashLength * 2;

	const borderColor = tc(secondary.dark).setAlpha(1).toString();

	const borderColor2 = tc(secondary.dark).setAlpha(0).toString();

	const horizontalDash = `
		linear-gradient(to right, ${borderColor} 50%, ${borderColor2} 50%)
	`;

	const verticalDash = `
		linear-gradient(to bottom, ${borderColor} 50%, ${borderColor2} 50%)
	`;

	return {
		"@keyframes marching-ants": {
			"0%": {
				backgroundPosition: "0 0, 0 100%, 0 0, 100% 0"
			},
			"100%": {
				backgroundPosition: `
					${dashAnimDistance}px 0,
					-${dashAnimDistance}px 100%,
					0 -${dashAnimDistance}px,
					100% ${dashAnimDistance}px`
			}
		},
		base: {
			transition: theme.transitions.create(["opacity", "transform"], {
				duration: theme.transitions.duration.complex,
				easing: theme.transitions.easeInOut
			}),
			borderRadius: u / 2,
			borderColor: "transparent",
			background: `
				${horizontalDash},
				${horizontalDash},
				${verticalDash},
				${verticalDash}`,
			backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
			backgroundSize: `
				${dashLength}px ${borderWidth}px,
				${dashLength}px ${borderWidth}px,
				${borderWidth}px ${dashLength}px,
				${borderWidth}px ${dashLength}px`,
			backgroundPosition: "0 0, 0 100%, 0 0, 100% 0",
			backgroundColor: tc(secondary.main).setAlpha(0.3).toString()
		},
		inactive: {
			zIndex: -1,
			opacity: 0,
			transform: "scale(1.5)",
			width: 0,
			height: 0
		},
		active: {
			opacity: 1,
			position: "fixed",
			zIndex: theme.zIndex.tooltip,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			scale: "transform(1)",

			animation: `$marching-ants ${duration.complex}ms infinite linear`
		}
	};
});

export default DropzoneIndicator;
