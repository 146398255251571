import React, { useEffect } from "react";
import { IconButton, SvgIcon } from "@material-ui/core";

import { mdiContentCopy } from "@mdi/js";
import useClipboard from "react-use-clipboard";
import { navigate } from "@reach/router";
import { usePrevious } from "../use-previous";

const CopyClusterApiKeyButton = ({ cluster, linkToCluster }) => {
	const [isCopied, setIsCopied] = useClipboard(cluster.apiKey, {
		successDuration: 1000
	});

	const wasCopied = usePrevious(isCopied);

	useEffect(() => {
		if (isCopied && !wasCopied) {
			navigate(null, {
				state: {
					notification: {
						severity: "info",
						text: `${cluster.id} cluster API key copied!`,
						actionLink: linkToCluster,
						actionText: "View cluster"
					}
				}
			});
		}
	}, [cluster.id, isCopied, linkToCluster, wasCopied]);

	return (
		<IconButton onClick={setIsCopied} title="Copy API Key">
			<SvgIcon>
				<path d={mdiContentCopy} />
			</SvgIcon>
		</IconButton>
	);
};

export default CopyClusterApiKeyButton;
