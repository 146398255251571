import React, { forwardRef, useCallback, useContext } from "react";
import { Link } from "@reach/router";
import {
	Menu,
	MenuItem,
	ListItemText,
	ListSubheader,
	ListItemSecondaryAction,
	Switch,
	RootRef
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useLogout } from "@runly/ui";
import { DarkModeContext, belowPopProps } from "@runly/gatsby-theme";

import useLayout from "../context";

const _MenuLink = ({ handleClose, ...props }, ref) => (
	<RootRef rootRef={ref}>
		<Link onClick={handleClose} {...props} />
	</RootRef>
);

const MenuLink = forwardRef(_MenuLink);

const ProfileMenu = ({ user, anchorEl, ...props }) => {
	const { darkMode, setDarkMode } = useContext(DarkModeContext);
	const { containerMaxWidth, setContainerMaxWidth } = useLayout();

	const handleToggleDarkMode = useCallback(() => {
		setDarkMode(darkMode => !darkMode);
	}, [setDarkMode]);

	const handleToggleFullWidth = useCallback(() => {
		setContainerMaxWidth(w => (w === false ? "lg" : false));
	}, [setContainerMaxWidth]);

	const isFullWidth = containerMaxWidth === false;

	const handleDarkModeSwitchChange = useCallback(
		evt => {
			setDarkMode(evt.target.checked);
		},
		[setDarkMode]
	);

	const classes = useStyles();

	const logout = useLogout();
	return (
		<Menu
			{...belowPopProps}
			id="account-menu"
			anchorEl={anchorEl}
			keepMounted
			open={!!anchorEl}
			onClose={props.onClose}
			MenuListProps={{ className: classes.menuContainer }}
		>
			<ListSubheader>
				{user.name}
				{"<"}
				{user.email}
				{">"}
			</ListSubheader>
			<MenuItem component={MenuLink} to="/dashboard/account">
				Account
			</MenuItem>
			<MenuItem divider onClick={logout}>
				Logout
			</MenuItem>

			<MenuItem onClick={handleToggleDarkMode}>
				<ListItemText primary="Dark mode" />
				<ListItemSecondaryAction>
					<Switch
						inputProps={{ "aria-label": "Toggle dark mode" }}
						onChange={handleDarkModeSwitchChange}
						checked={darkMode}
					/>
				</ListItemSecondaryAction>
			</MenuItem>
			<MenuItem onClick={handleToggleFullWidth}>
				<ListItemText primary="Full width" />
				<ListItemSecondaryAction>
					<Switch
						inputProps={{ "aria-label": "Toggle full width" }}
						onChange={handleToggleFullWidth}
						checked={isFullWidth}
					/>
				</ListItemSecondaryAction>
			</MenuItem>
		</Menu>
	);
};

const useStyles = makeStyles(theme => {
	return {
		menuContainer: {
			minWidth: theme.spacing(32)
		}
	};
});

export default ProfileMenu;
