import React from "react";
import { useLocation } from "@reach/router";

import { startCase } from "lodash";

import UserProfileLink from "./link";
import UserProfileChip from "./chip";
import UserProfileListItem from "./list-item";
import UserProfileCard from "./card";
import UserProfileAvatar from "./avatar";

import { useFetchUserProfile } from "./api";
import useUserProfiles from "./context";

const VARIANTS = {
	Link: UserProfileLink,
	Chip: UserProfileChip,
	ListItem: UserProfileListItem,
	Card: UserProfileCard,
	Avatar: UserProfileAvatar
};

const UserProfile = ({ variant = "Link", baseElevation = 2, ...props }) => {
	const { org } = useUserProfiles();
	if (!VARIANTS[variant])
		throw new TypeError(
			`variant \`${variant}\` not found. did you mean \`${startCase(
				variant
			)}\`?`
		);

	const isProfileAlreadyLoaded = !!props.nickname;

	const { body, ...userProfileReq } = useFetchUserProfile(props.userId);

	const theProps = isProfileAlreadyLoaded
		? props
		: { ...props, ...body, ...userProfileReq };

	const VariantComponent = VARIANTS[variant];

	const location = useLocation();

	return (
		<VariantComponent
			org={org}
			baseElevation={baseElevation}
			returnUrl={location.pathname}
			returnState={location.state}
			{...theProps}
		/>
	);
};

export default UserProfile;
