import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useMemo
} from "react";

const LayoutContext = createContext();

const INITIAL_WIDTH = "lg";

export const Provider = props => {
	const [containerMaxWidth, setContainerMaxWidth] = useState(INITIAL_WIDTH);

	const value = useMemo(() => ({ containerMaxWidth, setContainerMaxWidth }), [
		containerMaxWidth
	]);

	return (
		<LayoutContext.Provider value={value}>
			{props.children}
		</LayoutContext.Provider>
	);
};

const useLayout = () => {
	return useContext(LayoutContext) || {};
};

export const useWidth = width => {
	const { containerMaxWidth, setContainerMaxWidth } = useLayout();
	const [initialWidth] = useState(containerMaxWidth);

	useEffect(() => {
		setContainerMaxWidth(width);
		return () => {
			setContainerMaxWidth(initialWidth);
		};
	}, [initialWidth, setContainerMaxWidth, width]);
};

export const useFullWidth = () => useWidth(false);

export default useLayout;
