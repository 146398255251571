import React, { useCallback } from "react";

import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Collapse,
	Fade,
	Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
	ArrowForward as ArrowForwardIcon,
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
	Close as CloseIcon
} from "@material-ui/icons";

import { Link } from "@runly/gatsby-theme";

import DocLink from "../doc-link";
import useLocalStorage from "../local-storage";

import { useFetchQuickStart } from "./api";

const QuickStart = ({ org, children }) => {
	const classes = useGridStyles();

	const [isDismissed, setIsDismissed] = useLocalStorage(
		`${org}-qs-dismiss`,
		false
	);

	const onDismiss = useCallback(() => setIsDismissed(true), [setIsDismissed]);

	const { isFetched, body: qs } = useFetchQuickStart(org);
	if (!isFetched) return children;

	const allChecked = qs.hasConnected && qs.hasPackage && qs.hasRun;
	const isDone = allChecked && isDismissed;

	return (
		<>
			<Collapse in={!isDone} appear>
				<Fade in={!isDone} appear timeout={1500}>
					<div className={classes.container}>
						<Grid container className={classes.topGrid}>
							<Grid item xs={12} md={6}>
								<Typography variant="h3" component="h1">
									Welcome to Runly!
								</Typography>
								<Typography>
									To get you started we added an environment and cluster to your
									org. You're three easy steps away from running your first job.
									Learn more in the{" "}
									<DocLink href="/getting-started/">
										Quick Start Guide <ArrowForwardIcon fontSize="inherit" />
									</DocLink>
									.
								</Typography>
							</Grid>
						</Grid>
						<Grid container justify="center" spacing={1}>
							<Grid item xs={12} sm={4} md={3} className={classes.stepGrid}>
								<Step title="Connect" done={qs.hasConnected}>
									{qs.cluster ? (
										<Link to={`/dashboard/${org}/clusters/${qs.cluster}`}>
											Connect a node
										</Link>
									) : (
										<>Connect a node</>
									)}{" "}
									to run jobs on.
								</Step>
							</Grid>

							<Grid item xs={12} sm={4} md={3} className={classes.stepGrid}>
								<Step title="Upload" done={qs.hasPackage}>
									Upload your job in a NuGet package.
								</Step>
							</Grid>

							<Grid item xs={12} sm={4} md={3} className={classes.stepGrid}>
								<Step title="Run" done={qs.hasRun}>
									Queue your job using a JSON config.
								</Step>
							</Grid>
						</Grid>

						{allChecked ? (
							<Button onClick={onDismiss} className={classes.dismissBtn}>
								<CloseIcon /> Dismiss
							</Button>
						) : null}
					</div>
				</Fade>
			</Collapse>
			{children ? <Collapse in={isDone}>{children}</Collapse> : null}
		</>
	);
};

const useGridStyles = makeStyles(theme => ({
	container: {
		position: "relative"
	},
	topGrid: {
		marginBottom: theme.spacing(3)
	},
	stepGrid: {
		display: "flex"
	},
	dismissBtn: {
		position: "absolute",
		top: 0,
		right: 0
	}
}));

const Step = ({ title, done, children }) => {
	const classes = useStepStyles();
	return (
		<Card className={classes.card}>
			<CardHeader
				avatar={<StepIcon done={done} />}
				title={title}
				titleTypographyProps={{ variant: "h5" }}
				classes={useCardHeaderStyles()}
			/>
			<CardContent classes={useCardContentStyles()}>{children}</CardContent>
		</Card>
	);
};

const useStepStyles = makeStyles({
	card: {
		width: "100%"
	}
});

const useCardHeaderStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2, 2, 1, 2)
	}
}));

const useCardContentStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1, 2)
	}
}));

const StepIcon = ({ done }) => {
	const classes = useIconStyles({ done });

	return done ? (
		<CheckCircleIcon fontSize="large" className={classes.icon} />
	) : (
		<ErrorIcon fontSize="large" className={classes.icon} />
	);
};

const useIconStyles = makeStyles(theme => ({
	icon: {
		color: ({ done }) =>
			done ? theme.palette.success.main : theme.palette.primary.main
	}
}));

export default QuickStart;
