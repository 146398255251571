import React, {
	useContext,
	createContext,
	useEffect,
	useMemo,
	useState,
	useCallback
} from "react";

import { analytics } from "@runly/gatsby-theme";

import { useFetchOrgAccounts } from "../account/api";
import { useCreateDefaultOrg } from "./api";

const OrgContext = createContext();

const useOnboarding = () => {
	const accountReq = useFetchOrgAccounts();

	const needToCreateDefaultOrg =
		accountReq.isFetched && !accountReq.body.length;

	const orgReq = useCreateDefaultOrg(needToCreateDefaultOrg);

	const orgs = useMemo(() => {
		if (needToCreateDefaultOrg) {
			return orgReq.body
				? [
						{
							id: orgReq.body.id,
							name: orgReq.body.name,
							role: "Owner"
						}
				  ]
				: [];
		}

		return accountReq.body;
	}, [accountReq.body, needToCreateDefaultOrg, orgReq.body]);

	useEffect(() => {
		if (orgReq.isFetched) {
			analytics("event", "Signup", {
				event_category: "Account",
				event_label: orgReq.name,
				value: 0 // ain't no money here
			});
		}
	}, [orgReq.isFetched, orgReq.name]);

	const result = useMemo(() => {
		if (!needToCreateDefaultOrg) {
			return accountReq;
		}

		return {
			...orgReq,
			isFetching:
				accountReq.isFetching || (needToCreateDefaultOrg && orgReq.isFetching),
			isFetched:
				accountReq.isFetched && (!needToCreateDefaultOrg || orgReq.isFetched),
			error: accountReq.error || orgReq.error,
			body: orgs
		};
	}, [accountReq, needToCreateDefaultOrg, orgReq, orgs]);

	return result;
};

export const Provider = props => {
	const req = useOnboarding();

	return <OrgRoot {...props} orgs={req.body} />;
};

const OrgRoot = ({ children, orgs }) => {
	const [idMap, setIdMap] = useState({});

	const onIdChange = useCallback(
		(oldId, newId) => setIdMap(m => ({ ...m, [oldId]: newId })),
		[]
	);

	orgs = useMemo(
		() =>
			orgs?.map(o => {
				if (idMap[o.id]) {
					return { ...o, id: idMap[o.id] };
				}
				return o;
			}),
		[idMap, orgs]
	);

	const result = useMemo(() => ({ orgs, onIdChange }), [onIdChange, orgs]);

	return <OrgContext.Provider value={result}>{children}</OrgContext.Provider>;
};

export const useOrgs = () => {
	const { orgs } = useContext(OrgContext) || {};
	return orgs;
};

export const useOrg = org => {
	const orgs = useOrgs();

	return useMemo(() => {
		if (!orgs) return null;
		return orgs.find(o => o.id == org);
	}, [org, orgs]);
};

export const useOnIdChange = () => {
	const { onIdChange } = useContext(OrgContext) || {};
	return onIdChange;
};
