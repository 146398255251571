import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import { makeStyles } from "@material-ui/core/styles";
import {
	AppBar,
	Typography,
	Container,
	IconButton,
	Card,
	Box
} from "@material-ui/core";
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons";

import {
	LogoIcon,
	Footer,
	RUNLY_APPBAR_GRADIENT,
	RUNLY_APPBAR_GRADIENT_L
} from "@runly/gatsby-theme";

import OrgChooser from "../org/chooser";
import { useCanEditOrg } from "../org/permissions";

import Profile from "./profile";
import NavTabs from "./nav-tabs";

import Divider from "../divider";

import RefableLink from "../refable-link";
import useLayout from "./context";
import BackgroundArrows from "./bg-arrows";
import HelpMenu from "./help-menu";

const useDefaultNavTabs = org => {
	const canEditOrg = useCanEditOrg(org);

	return useMemo(() => {
		const tabs = [{ href: `/dashboard/${org}`, label: "Dashboard" }];

		if (canEditOrg) {
			tabs.push({ href: `/dashboard/${org}/settings`, label: "Settings" });
		}

		return tabs;
	}, [canEditOrg, org]);
};

const Layout = ({
	location,
	breadCrumb,
	org,
	navTabs,
	toolbar,
	children,
	maxWidth
}) => {
	let { containerMaxWidth } = useLayout();
	containerMaxWidth = maxWidth != undefined ? maxWidth : containerMaxWidth;

	const classes = useStyles();

	const defaultNavTabs = useDefaultNavTabs(org);

	const theNavTabs =
		navTabs ||
		(navTabs !== false ? (
			<NavTabs tabs={defaultNavTabs} location={location} />
		) : null);

	return (
		<div className={classes.root}>
			<Helmet titleTemplate="%s | Runly" />

			<AppBar elevation={0} position="relative" className={classes.appBar}>
				<Container maxWidth={containerMaxWidth}>
					<div className={classes.toolbar}>
						<IconButton
							title="Runly"
							className={classes.lilLogo}
							component={RefableLink}
							to={org ? `/dashboard/${org}` : `/dashboard`}
						>
							<LogoIcon />
						</IconButton>
						{org ? (
							<OrgChooser
								org={org}
								className={classes.orgChooser}
								isBreadcrumb={!!breadCrumb}
							/>
						) : null}
						{org && breadCrumb ? <ArrowRightIcon /> : null}
						{breadCrumb ? (
							<Typography
								className={classes.breadCrumb}
								component="h1"
								variant="h6"
								color="inherit"
								noWrap
							>
								{breadCrumb}
							</Typography>
						) : null}

						<div className={classes.profileContainer}>
							<HelpMenu className={classes.helpIcon} />
							<Profile />
						</div>
					</div>
					{theNavTabs ? <Divider /> : null}
				</Container>
				<Container maxWidth={containerMaxWidth}>
					{theNavTabs ? theNavTabs : <div />}
				</Container>
			</AppBar>
			{toolbar ? (
				<Card elevation={4}>
					<Container maxWidth={containerMaxWidth}>
						<Box py={1}>{toolbar}</Box>
					</Container>
				</Card>
			) : null}
			<Container
				maxWidth={containerMaxWidth}
				className={classes.arrowsContainer}
			>
				<div className={classes.bgArrows}>
					<BackgroundArrows />
				</div>
			</Container>
			<main className={classes.content} data-testid="layout-main">
				<Container maxWidth={containerMaxWidth} className={classes.container}>
					{children}
				</Container>
			</main>
			<Footer maxWidth={containerMaxWidth} />
		</div>
	);
};

const useStyles = makeStyles(theme => {
	const foreground = theme.palette.getContrastText(RUNLY_APPBAR_GRADIENT_L);
	return {
		"@global": {
			html: {
				scrollBehavior: "smooth",
				"@media screen and (prefers-reduced-motion: reduce)": {
					scrollBehavior: "auto"
				}
			},
			"*": {
				transition: theme.transitions.create(["background-color"])
			}
		},
		root: {
			display: "flex",
			flexDirection: "column",
			minHeight: "100vh"
		},
		toolbar: {
			flexGrow: 1,
			display: "flex",
			padding: theme.spacing(1, 0),
			alignItems: "center"
		},
		appBar: {
			background: RUNLY_APPBAR_GRADIENT,
			color: foreground,
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			flexShrink: 1
		},
		lilLogo: {
			color: foreground,
			marginLeft: theme.spacing(1.5)
		},
		helpIcon: {
			color: foreground,
			marginRight: theme.spacing(1.5)
		},
		breadCrumb: {
			display: "flex",
			alignItems: "center"
		},
		profileContainer: {
			marginLeft: "auto"
		},
		content: {
			flexGrow: 1,
			flexShrink: 0,
			overflow: "auto",
			display: "flex",
			flexDirection: "column"
		},
		container: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			position: "relative",
			flexGrow: 1,
			flexShrink: 0,
			display: "flex",
			flexDirection: "column"
		},
		arrowsContainer: {
			position: "relative",
			height: 0,
			overflow: "visible"
		},
		bgArrows: {
			zIndex: -1,
			position: "absolute",
			top: 0,
			right: 0
		}
	};
});

export default Layout;
