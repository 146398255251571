import React, { useContext, useEffect, createContext } from "react";

import { useFetchAccount } from "./api";
import { usePrevious } from "../use-previous";

const AccountContext = createContext();

export const Provider = ({ children }) => {
	const req = useFetchAccount();

	const reqBody = req?.body;

	const prevReqBody = usePrevious(reqBody);

	useEffect(() => {
		if (reqBody && !req.error && !prevReqBody) {
			const { id, email, name: username } = reqBody;

			window?.Rollbar?.configure?.({
				payload: { person: { id, email, username } }
			});
		}
	}, [prevReqBody, req.error, reqBody]);

	return (
		<AccountContext.Provider value={req}>{children}</AccountContext.Provider>
	);
};

export const useAccount = () => {
	const req = useContext(AccountContext) || {};
	return req.body;
};
