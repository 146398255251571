import { useEffect } from "react";
import { usePrevious } from "./use-previous";
import { navigate } from "@reach/router";

const useMutationHandler = ({
	req,
	successNotification,
	errorNotification,
	location,
	successRedirectUrl = location?.state?.returnUrl
}) => {
	const isSuccessTransition = useSuccessTransition(req);
	const isErrorTransition = useErrorTransition(req);

	const messageId = getIdFromReq(req);

	useEffect(() => {
		if (isSuccessTransition) {
			navigate(successRedirectUrl, {
				state: {
					notification: successNotification
						? {
								messageId,
								severity: "success",
								title: "Success",
								...(successNotification || {})
						  }
						: null
				}
			});
		}
		if (isErrorTransition) {
			const fallbackMessage = errorNotification
				? `Problem ${errorNotification.verb} ${errorNotification.description}`
				: null;
			navigate(null, {
				state: {
					notification: {
						messageId,
						severity: "error",
						title: errorNotification?.title || fallbackMessage || "Error",
						text: req?.error?.message,
						...(errorNotification || {})
					}
				}
			});
		}
	});
};

export const useInitRequestTransition = ({ isFetching }) => {
	const wasFetching = usePrevious(isFetching);

	const isInitRequestTransition = !wasFetching && isFetching;

	return isInitRequestTransition;
};
export const useSuccessTransition = ({ isFetching, isFetched, error }) => {
	const wasFetching = usePrevious(isFetching);
	const isSuccessTransition = !!(
		wasFetching &&
		!isFetching &&
		isFetched &&
		!error
	);

	return isSuccessTransition;
};

const useErrorTransition = ({ isFetching, error }) => {
	const wasFetching = usePrevious(isFetching);
	const isErrorTransition = !!(wasFetching && !isFetching && error);

	return isErrorTransition;
};

const getIdFromReq = req =>
	req?.body?.id || req?.headers?.Date || new Date().getTime();

export default useMutationHandler;
