import React, { useState } from "react";
import {
	parseISO,
	formatDistanceStrict,
	format,
	formatISO,
	isDate,
	isValid,
	differenceInMilliseconds
} from "date-fns";
import useInterval from "@use-it/interval";

const DateTime = ({
	value,
	refreshUnderMinute = 5e3,
	refreshOverMinute = 2e4,
	addSuffix = true
}) => {
	const date = isDate(value) ? value : parseISO(value);
	const elapsedMs = differenceInMilliseconds(date, new Date());
	const elapsedSeconds = elapsedMs / 1e3;
	const elapsedMinutes = elapsedSeconds < 60 ? 0 : elapsedSeconds / 60;
	const pollInterval =
		elapsedMinutes > 0 ? refreshOverMinute : refreshUnderMinute;

	const [twiddled, setTwiddled] = useState(false);

	useInterval(() => {
		setTwiddled(t => !t);
	}, pollInterval);

	if (!isValid(date)) {
		return <span>Never</span>;
	}
	const relativeValue =
		Math.abs(elapsedSeconds) < 1
			? "just now"
			: formatDistanceStrict(date, new Date(), {
					addSuffix
			  });
	const titleValue = format(date, "MMM do yyyy 'at' h:mm a");

	return (
		<time
			dateTime={formatISO(date)}
			title={titleValue}
			data-twiddled={twiddled}
		>
			{relativeValue}
		</time>
	);
};

export default DateTime;
