import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import { useFetchPkgVersion } from "./api";
import FakeSuspense from "../fake-suspense";
import UserActivity from "../user-profile/activity";

const PkgVersionCard = ({
	org,
	version,
	pkgId,
	baseElevation = 2,
	cardProps = { elevation: Math.min(24, baseElevation + 4) }
}) => {
	const vReq = useFetchPkgVersion({ org, version, pkgId });

	return (
		<Card {...cardProps}>
			<CardHeader
				title={
					<>
						{pkgId} v{version}
					</>
				}
			/>
			<FakeSuspense req={vReq} description="version info">
				<PkgVersionCardContent {...{ vReq }} />
			</FakeSuspense>
		</Card>
	);
};

const PkgVersionCardContent = ({ vReq }) => (
	<CardContent style={{ minWidth: "24em" }}>
		<UserActivity
			verb="uploaded"
			by={vReq.body.uploadedBy}
			at={vReq.body.uploadedAt}
		/>
	</CardContent>
);

export default PkgVersionCard;
