import React from "react";
import { Typography } from "@material-ui/core";
import { Link, RUNLY_APPBAR_GRADIENT_L } from "@runly/gatsby-theme";
import { makeStyles } from "@material-ui/core/styles";
import tc from "tinycolor2";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";

const NavTab = ({ label, href, isActive, isGoBack, linkState }) => {
	const classes = useStyles({ isActive, isGoBack });

	return (
		<Link className={classes.root} to={href} state={linkState}>
			<Typography className={classes.linkText} component="div" variant="button">
				{isGoBack ? <ChevronLeftIcon /> : null} {label}
			</Typography>
		</Link>
	);
};

const useStyles = makeStyles(theme => {
	const textColor = theme.palette.getContrastText(RUNLY_APPBAR_GRADIENT_L);

	return {
		root: {
			padding: ({ isGoBack }) =>
				isGoBack ? theme.spacing(1, 0) : theme.spacing(1, 2),
			color: ({ isActive }) =>
				tc(textColor)
					.setAlpha(isActive ? 1 : 0.75)
					.toString(),
			transition: theme.transitions.create(["color"]),
			"&, &:hover, &:focus": {
				textDecoration: "none"
			},
			"&:hover, &:focus": {
				color: textColor
			},
			marginBottom: ({ isActive }) => (isActive ? -2 : null),
			borderBottom: ({ isActive }) =>
				isActive
					? `4px solid ${tc(theme.palette.secondary.main)
							.setAlpha(0.5)
							.toString()}`
					: null
		},
		linkText: {
			textTransform: "none",
			fontSize: "1rem",
			fontWeight: ({ isActive }) => (isActive ? 500 : 300),
			display: "flex",
			alignItems: "center"
		}
	};
});

export default NavTab;
