import React, { useState, useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar, IconButton, Slide, Portal } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const SnackTransition = props => <Slide {...props} direction="left" />;

const Notification = ({
	severity = "info",
	variant = "filled",
	messageId,
	anchorOrigin = {
		vertical: "bottom",
		horizontal: "right"
	},
	autoHideDuration = 6000,
	action = [],
	isOpen,
	handleClose,
	onExited,
	title,
	children
}) => {
	const classes = useStyles();
	const [_messageId, _setMessageId] = useState(messageId);

	useEffect(() => {
		if (!_messageId) {
			_setMessageId(`notification-${new Date().getTime()}`);
		}
		// only call once on mount if not populated
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Portal>
			<Snackbar
				key={_messageId}
				anchorOrigin={anchorOrigin}
				open={isOpen}
				onClose={handleClose}
				onExited={onExited}
				autoHideDuration={handleClose ? autoHideDuration : undefined}
				ContentProps={{
					"aria-describedby": messageId
				}}
				TransitionComponent={SnackTransition}
			>
				<Alert
					{...{ severity, variant }}
					action={[
						...action,
						<IconButton
							key="close"
							aria-label="close"
							color="inherit"
							className={classes.close}
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
					]}
				>
					{title ? <AlertTitle>{title}</AlertTitle> : null}
					<span id={messageId}>{children}</span>
				</Alert>
			</Snackbar>
		</Portal>
	);
};

const useStyles = makeStyles(theme => ({
	close: {
		padding: theme.spacing(0.5)
	}
}));

export default Notification;
