import { useFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

import useUserProfiles from "./context";
import { useSuccessTransition } from "../mutation-handler";
import { useEffect } from "react";

export const useFetchUserProfile = userId => {
	const { cache, setCache, org } = useUserProfiles();
	const existing = cache[userId];

	const { url, token: bearerToken } = useRunlyConfig();

	const isSystem = userId?.toLowerCase?.() === "system";

	const req = useFetch(
		url && bearerToken && org && userId && !isSystem && !existing
			? `${url}/${org}/members/${encodeURIComponent(userId)}`
			: null,
		{ bearerToken }
	);

	const justLoaded = useSuccessTransition(req);

	useEffect(() => {
		if (justLoaded && !existing) {
			const isOwner = req.body?.role === "Owner";
			setCache(prev => ({
				...prev,
				[userId]: { ...req.body, isOwner, isSystem }
			}));
		}
	}, [existing, isSystem, justLoaded, req.body, setCache, userId]);

	useEffect(() => {
		if (isSystem && !existing) {
			setCache(prev => ({
				...prev,
				[userId]: {
					isSystem,
					nickname: "System",
					userId: "System",
					id: "System"
				}
			}));
		}
	});

	return { ...req, body: cache[userId] };
};
