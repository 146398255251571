import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchClusters = ({ org, env }) => {
	const { url: baseUrl, token: bearerToken } = useRunlyConfig();

	const url =
		baseUrl && bearerToken
			? org && env
				? `${baseUrl}/${org}/environments/${env}/clusters`
				: org
				? `${baseUrl}/${org}/clusters/`
				: null
			: null;

	return useFetch(url, {
		method: "GET",
		bearerToken
	});
};

export const useSaveNewCluster = (org, cluster) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(url && bearerToken ? `${url}/${org}/clusters/` : null, {
		method: "POST",
		bearerToken,
		body: JSON.stringify(cluster)
	});
};

export const useDeleteCluster = (org, cluster) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/clusters/${cluster}` : null,
		{
			method: "DELETE",
			bearerToken
		}
	);
};
